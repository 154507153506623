const amenities_facilities_group = "amenities_facilities_group";
const location_group = "location_group";
const properties_good_for_group = "properties_good_for_group";

export const searchUrlKey: any = {
  hottub: amenities_facilities_group,
  pool: amenities_facilities_group,
  beach: location_group,
  lake: location_group,
  mountain: location_group,
  ocean: location_group,
  ski: location_group,
  familyfriendly: properties_good_for_group,
};

export const searchUrlValue: any = {
  hottub: "hot_tub",
  pool: "pool",

  beach: "beach",
  lake: "lake_location",
  mountain: "mountains_location",
  ocean: "ocean",
  ski: "skiin_skiout",

  familyfriendly: "families",

  APARTMENT: "apartment",
  BED_AND_BREAKFAST: "bed_and_breakfast",
  CABIN: "cabin",
  CASTLE: "castle",
  CHALET: "chalet",
  COUNTRY_HOUSE: "chateau",
  COTTAGE: "cottage",
  AGRITOURISM: "farmhouse",
  GUEST_HOUSE: "guest_house",
  APART_HOTEL: "hotels",
  VACATION_HOME: "house",
  HOUSE_BOAT: "houseboat",
  LODGE: "lodge",
  HOTEL_RESORT: "resort",
  CARAVAN_PARK: "RV",
  TOWNHOUSE: "townhouse",
  VILLA: "villa",
};
