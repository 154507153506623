import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading, UitkSubheading, UitkText } from "@egds/react-core/text";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkViewRow } from "@egds/react-core/view";
import { UitkCard } from "@egds/react-core/cards";
import { UitkSticky } from "@egds/react-core/sticky";
import { ViewLarge, ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import {
  LodgingSearchHeroProps,
  LodgingSearchHeroFlexModuleResult,
  HeadingsProps,
  LodgingSearchProps,
  LayoutWrapperProps,
} from "./typings";
import {
  LodgingSearchForm as SearchForm,
  LodgingSearchFormInputs as SearchFormInputs,
  LodgingSearchFormOptions as SearchFormOptions,
} from "@shared-ui/retail-search-tools-product";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { searchUrlKey, searchUrlValue } from "components/utility/FlexToSearchUrlFilterMappings";
import { Field } from "@egds/react-core/form";
import { useSRPPrefetchAssetsExperiment } from "../../utility/useSRPPrefetchAssetsExperiment";
import { PrefetchProductType } from "src/components/utility/usePrefetchAssets";
import { isVariantEnabled } from "src/components/utility/experiment";

type RemoveReadonly<T> = T extends readonly unknown[] | object // traverses arrays and objects
  ? { -readonly [P in keyof T]: RemoveReadonly<T[P]> }
  : T;

const LodgingSearch: React.FC<LodgingSearchProps> = ({
  enableAutoOpenCalendar: enableAutoOpenCalender,
  sticky,
  enablePrefillDestination,
  prefillInputs,
  xPageId,
}) => {
  const flexibleSearchProps = {
    includeFlexibleCalendarContent: true,
    includeFlexibleDatesContent: true,
  };

  const { prefetchSRPAssets } = useSRPPrefetchAssetsExperiment(PrefetchProductType.LODGING_SRP);

  const handleFieldChange = React.useCallback(
    (field: Field) => {
      prefetchSRPAssets(field);
    },
    [prefetchSRPAssets]
  );

  const locationFieldProps = {
    additionalAdapterFields: {
      excludeRoomsLabelForRecentHistory: true,
      historyDetail: true,
      xPageId,
      popularFilter: true,
    },
  };

  const lodgingSearchExperimentProps: SearchFormOptions = {
    ...flexibleSearchProps,
    ...locationFieldProps,
    onFieldChange: handleFieldChange,
  };

  const searchFormProps: SearchFormOptions & { inputs: SearchFormInputs } = {
    ...lodgingSearchExperimentProps,
    inputs: {},
    autoOpenCalendar: enableAutoOpenCalender,
    redirectToSearchResultOnSubmit: true,
    enableReflection: true,
  };

  return (
    <UitkCard border padded overflow backgroundTheme="secondary">
      <section data-testid={`${sticky ? "sticky-" : ""}lodging-search-form-hero`}>
        {enablePrefillDestination && prefillInputs ? (
          <div data-testid="prefill-lodging-search-form-hero">
            <SearchForm {...searchFormProps} inputs={prefillInputs} />
          </div>
        ) : (
          <SearchForm {...searchFormProps} />
        )}
      </section>
    </UitkCard>
  );
};

LodgingSearch.displayName = "LodgingSearch";

export const Headings: React.FC<HeadingsProps> = ({
  title,
  subTitle,
  textAlign,
  textAlignSmallViewport,
  isHeroImageVariantEnabled,
}) => {
  const theme = isHeroImageVariantEnabled ? "emphasis" : "inverse";

  return (
    <>
      {title && (
        <UitkSpacing
          margin={{
            small: {
              blockend: "three",
            },
            medium: {
              blockend: "six",
            },
          }}
        >
          <div>
            <Viewport>
              <ViewSmall>
                <UitkHeading tag="h1" size={3}>
                  <UitkSpacing
                    padding={{
                      blockend: "one",
                    }}
                  >
                    <UitkText theme={theme} size={700} align={textAlignSmallViewport}>
                      {title}
                    </UitkText>
                  </UitkSpacing>
                </UitkHeading>
              </ViewSmall>
              <ViewMedium>
                <UitkHeading tag="h1" size={2}>
                  <UitkSpacing
                    padding={{
                      blockend: "one",
                    }}
                  >
                    <UitkText theme={theme} size={isHeroImageVariantEnabled ? 700 : 900} align={textAlign}>
                      {title}
                    </UitkText>
                  </UitkSpacing>
                </UitkHeading>
              </ViewMedium>
            </Viewport>
            {subTitle && (
              <Viewport>
                <ViewSmall>
                  <UitkSubheading tag="p">
                    <UitkText theme={theme} size={500} weight="bold" align={textAlignSmallViewport}>
                      {subTitle}
                    </UitkText>
                  </UitkSubheading>
                </ViewSmall>
                <ViewMedium>
                  <UitkSubheading tag="p">
                    <UitkText theme={theme} size={700} weight="bold" align={textAlign}>
                      {subTitle}
                    </UitkText>
                  </UitkSubheading>
                </ViewMedium>
              </Viewport>
            )}
          </div>
        </UitkSpacing>
      )}
    </>
  );
};

export const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children, isHeroImageVariantEnabled }) => {
  if (!isHeroImageVariantEnabled) {
    return (
      <UitkLayoutPosition
        type="absolute"
        position={{ top: "zero", right: "zero", bottom: "zero", left: "zero" }}
        cloneElement
      >
        <UitkViewRow layout="centered" canvasTheme="none">
          {children}
        </UitkViewRow>
      </UitkLayoutPosition>
    );
  }

  return (
    <UitkLayoutPosition type="relative">
      <Viewport>
        <ViewSmall>
          <>{children}</>
        </ViewSmall>
        <ViewLarge>
          <UitkViewRow layout="centered" canvasTheme="none">
            {children}
          </UitkViewRow>
        </ViewLarge>
      </Viewport>
    </UitkLayoutPosition>
  );
};

/**
 * This is a temporary solution (almost a lift and shift for Vrbo use cases) and it's considered Tech debt.
 * Supporting background images with search forms is something that is still in the discovery phase from the Universal Search Form perspective.
 * Eventually this module will be removed.
 * Use this module with DestinationHero
 * Please don't use this module outside of the original scope it was intended, if you have any question please reach [#search-squad](https://expedia.slack.com/archives/C01PXSZN9GD)
 */
export const LodgingSearchHero = withStores(
  "flexModuleModelStore",
  "context",
  "page"
)(
  observer((props: LodgingSearchHeroProps) => {
    const { templateComponent, flexModuleModelStore, context, page } = props;

    /* istanbul ignore if */
    if (!templateComponent || !flexModuleModelStore) {
      return null;
    }

    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as LodgingSearchHeroFlexModuleResult | null;

    if (!model) {
      return null;
    }

    const {
      title,
      subTitle,
      textAlign,
      textAlignSmallViewport,
      enableAutoOpenCalender,
      enablePrefillDestination,
    } = model;

    const isHeroImageVariantEnabled = isVariantEnabled(context, "Home_Hero_Image_Optimization_V2");

    const hasHeadings = title || subTitle;

    const prefillInputs = getPrefilledInputs(context);

    const lodgingSearchProps: LodgingSearchProps = {
      enableAutoOpenCalendar: enableAutoOpenCalender,
      enablePrefillDestination,
      prefillInputs,
      brand: context?.site?.brand,
      deviceType: context?.deviceInformation?.type,
      pageType: context?.searchContext?.pageType,
      xPageId: page?.pageId ?? "",
    };

    return (
      <LayoutWrapper isHeroImageVariantEnabled={isHeroImageVariantEnabled}>
        <UitkSpacing padding={{ inline: "three", block: isHeroImageVariantEnabled ? "eight" : "unset" }}>
          <UitkLayoutFlex direction="column" justifyContent="center">
            {hasHeadings && (
              <Headings
                textAlign={textAlign}
                textAlignSmallViewport={textAlignSmallViewport}
                title={title}
                subTitle={subTitle}
                isHeroImageVariantEnabled={isHeroImageVariantEnabled}
              />
            )}
            <Viewport>
              <ViewSmall>
                <LodgingSearch {...lodgingSearchProps} />
              </ViewSmall>
              <ViewMedium>
                <UitkSticky zIndex={400}>
                  <LodgingSearch {...lodgingSearchProps} sticky />
                </UitkSticky>
              </ViewMedium>
            </Viewport>
          </UitkLayoutFlex>
        </UitkSpacing>
      </LayoutWrapper>
    );
  })
);

LodgingSearchHero.displayName = "LodgingSearchHero";

export default LodgingSearchHero;

export const getPrefilledInputs = (context: ExtendedContextStore): SearchFormInputs => {
  const getRegionId = () => {
    if (context?.searchContext?.location) {
      return context.searchContext.location.id.toString();
    }
    return null;
  };

  const searchCriteriaInput: RemoveReadonly<SearchFormInputs> = {
    propertySearchCriteriaInput: {
      primary: { destination: { regionId: getRegionId() }, rooms: [] },
    },
  };

  if (context?.searchContext?.alternativeAccommodation?.lodgingUrlCode) {
    const searchUrlFilterValue = searchUrlValue[context.searchContext.alternativeAccommodation.lodgingUrlCode];
    if (searchUrlFilterValue) {
      searchCriteriaInput.propertySearchCriteriaInput!.secondary = {
        selections: [{ id: "property_type_group", value: searchUrlFilterValue }],
      };
    }
  } else if (context?.searchContext?.affinity?.code) {
    const searchUrlFilterKey = searchUrlKey[context.searchContext.affinity.code];
    const searchUrlFilterValue = searchUrlValue[context.searchContext.affinity.code];
    if (searchUrlFilterKey && searchUrlFilterValue) {
      searchCriteriaInput.propertySearchCriteriaInput!.secondary = {
        selections: [{ id: searchUrlFilterKey, value: searchUrlFilterValue }],
      };
    }
  }

  return searchCriteriaInput;
};
